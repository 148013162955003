const chipClassNames =
  'bg-dark-alt rounded-lg px-2 py-2 text-xs lg:rounded-lg lg:text-sm lg:px-3';

const Intro = () => {
  return (
    <section className='flex flex-col items-center'>
      <img
        src='/images/kent-warren.jpg'
        alt=''
        title='Kent Warren'
        className='bg-medium mb-4 h-48 w-48 rounded-full lg:h-52 lg:w-52'
      />
      <h1 className='mb-4'>Kent Warren</h1>
      <p className='mb-6 text-center'>
        Technical program director and mentor with
        <br />
        20+ years leading digital products
      </p>
      {/* chips */}
      <div className='mb-2 flex flex-col items-center justify-center gap-2 sm:flex-row'>
        <p className={`sm:mb-0 ${chipClassNames}`}>
          Software Engineering Leader
        </p>
        <p className={`sm:mb-0 ${chipClassNames}`}>
          Professional Scrum Master II
        </p>
      </div>
      <p className={chipClassNames}>Agile Project Manager</p>
    </section>
  );
};
export default Intro;
